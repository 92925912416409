.bannerMatch {
    padding: 0 100px;
    margin-top: 25px;
    display: flex;
    justify-content: center;

    @include XL {
        padding: 0 50px;
    }

    @include L {
        padding: 0 25px;
    }

    @include M {
        padding: 0 20px;
    }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        &-title {
            font-size: 28px;
            text-align: center;

            @include L {
                font-size: 24px;
            }

            @include M {
                font-size: 22px;
            }
        }

        &-category {
            font-size: 22px;
            font-weight: 500;

            @include L {
                font-size: 20px;
            }

            @include M {
                font-size: 18px;
            }
        }

        &-score {
            font-size: 18px;
            
            @include L {
                font-size: 16px;
            }

            @include M {
                font-size: 14px;
            }
        }
    }
}