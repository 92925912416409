.score {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 20px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    box-shadow: 0 0 8px black;
    width: 35%;

    @include XL {
        width: 420px;
        gap: 50px;
    }

    @include M {
        width: 85%;
    }

    &-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        &-title {
            font-size: 26px;
            text-decoration: underline;

            @include M {
                font-size: 20px;
            }
        }

        &-error {
            font-size: 18px;
            color: rgba($color: red, $alpha: 1);
            text-align: center;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0 0 6px red;
            background-color: rgba($color: red, $alpha: 0.1);
            
            @include M {
                font-size: 16px;
            }
        }

        &-firstLetter {
            font-size: 18px;
            text-align: center;
            background-color: #3A9D23;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0 0 6px white;

            @include M {
                font-size: 16px;
            }

            &-span {
                color: black;
                padding: 5px;
                font-weight: 900;
            }
        }
    }

    &-value {
        display: flex;
        justify-content: center;

        &-description {
            font-size: 60px;

            @include M {
                font-size: 40px;
            }
        }
    }

    &-joker {
        display: flex;
        justify-content: center;
        gap: 15px;

        &-button {
            padding: 10px 20px;
            border: none;
            background-color: white;
            color: black;
            font-size: 18px;
            border-radius: 10px;
            transition: all 300ms ease-in-out;

            @include M {
                font-size: 14px;
                padding: 10px 15px;
            }

            &:hover {
                background-color: rgba($color: #3A9D23, $alpha: 1.0);
                color: white;
                transform: scale(1.05);
            }
        }

        &-buttonDisabled {
            display: none;
        }
    }

    &-restart {
        display: flex;
        justify-content: center;
        gap: 15px;

        &-button {
            padding: 10px 20px;
            border: none;
            background-color: white;
            color: black;
            font-size: 18px;
            border-radius: 10px;
            transition: all 300ms ease-in-out;

            @include M {
                font-size: 14px;
                padding: 10px 15px;
            }

            &:hover {
                background-color: rgba($color: #3A9D23, $alpha: 1.0);
                color: white;
                transform: scale(1.05);
            }
        }
    }
}