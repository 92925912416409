* {
  margin: 0;
  padding: 0;
  font-family: "Roboto Condensed", sans-serif;
}

body {
  background-color: rgba(58, 157, 35, 0.8);
}

.app {
  color: white;
  max-width: 1400px;
  margin: 0 auto;
}

.header {
  padding: 15px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1024px) {
  .header {
    padding: 15px 50px;
  }
}
@media (max-width: 768px) {
  .header {
    padding: 15px 25px;
  }
}
@media (max-width: 600px) {
  .header {
    padding: 15px 20px;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
  }
}
.header-left-link {
  text-decoration: none;
  color: white;
}
.header-left-link-title {
  font-weight: 900;
  letter-spacing: 1px;
  font-size: 28px;
}
@media (max-width: 768px) {
  .header-left-link-title {
    font-size: 26px;
  }
}
@media (max-width: 600px) {
  .header-left-link-title {
    font-size: 22px;
  }
}
.header-right-nav-link {
  text-decoration: none;
  color: white;
  box-shadow: 0 0 6px black;
  border-radius: 10px;
  background-color: black;
  padding: 5px 15px;
  letter-spacing: 1px;
  transition: all 300ms ease-in-out;
}
@media (max-width: 768px) {
  .header-right-nav-link {
    font-size: 14px;
  }
}
.header-right-nav-link:hover {
  transform: scale(1.05);
}

.bannerHome {
  margin-top: 50px;
  padding: 0 100px;
}
@media (max-width: 1024px) {
  .bannerHome {
    padding: 0 50px;
  }
}
@media (max-width: 768px) {
  .bannerHome {
    padding: 0 25px;
  }
}
@media (max-width: 600px) {
  .bannerHome {
    padding: 0 20px;
  }
}
.bannerHome-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
}
.bannerHome-container-image {
  -o-object-fit: cover;
     object-fit: cover;
  height: 50%;
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 0 6px white;
}
@media (max-width: 1024px) {
  .bannerHome-container-image {
    height: 70%;
    width: 60%;
  }
}
@media (max-width: 768px) {
  .bannerHome-container-image {
    height: 80%;
    width: 70%;
  }
}
@media (max-width: 600px) {
  .bannerHome-container-image {
    width: 100%;
  }
}
.bannerHome-container-title {
  text-align: center;
  width: 70%;
}
@media (max-width: 768px) {
  .bannerHome-container-title {
    font-size: 22px;
  }
}
@media (max-width: 600px) {
  .bannerHome-container-title {
    width: 100%;
    font-size: 20px;
  }
}
.bannerHome-container-link {
  text-decoration: none;
  color: white;
  box-shadow: 0 0 6px black;
  border-radius: 10px;
  background-color: black;
  padding: 10px 20px;
  letter-spacing: 1px;
  transition: all 300ms ease-in-out;
}
@media (max-width: 600px) {
  .bannerHome-container-link {
    font-size: 14px;
  }
}
.bannerHome-container-link:hover {
  transform: scale(1.1);
}

.cards {
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 50px;
}
@media (max-width: 1024px) {
  .cards {
    padding: 0 50px;
  }
}
@media (max-width: 768px) {
  .cards {
    gap: 25px;
    padding: 0 25px;
  }
}
@media (max-width: 600px) {
  .cards {
    padding: 0 20px;
  }
}
.cards-row {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .cards-row {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
}
.cards-row-container {
  background-color: #3A9D23;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 6px white;
  border-radius: 10px;
  align-items: center;
  width: 43%;
  padding: 20px;
  gap: 25px;
  transition: all 300ms ease-in-out;
}
@media (max-width: 768px) {
  .cards-row-container {
    width: 90%;
  }
}
.cards-row-container:hover {
  box-shadow: 0 0 50px white;
  transform: scale(1.05);
}
.cards-row-container-image {
  width: 75%;
  border-radius: 10px;
  box-shadow: 0 0 6px white;
}
@media (max-width: 1024px) {
  .cards-row-container-image {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .cards-row-container-image {
    width: 70%;
    height: 300px;
  }
}
@media (max-width: 600px) {
  .cards-row-container-image {
    height: 250px;
    width: 80%;
  }
}
@media (max-width: 425px) {
  .cards-row-container-image {
    height: 200px;
    width: 100%;
  }
}
.cards-row-container-title {
  font-size: 23px;
  font-weight: 900;
  text-decoration: underline;
}
@media (max-width: 600px) {
  .cards-row-container-title {
    font-size: 18px;
  }
}
.cards-row-container-description {
  text-align: center;
  letter-spacing: 1px;
}
@media (max-width: 600px) {
  .cards-row-container-description {
    font-size: 14px;
  }
}

.footer {
  margin-top: 50px;
  background-color: black;
  display: flex;
  padding: 30px;
  border-radius: 10px 10px 0 0;
}
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}
@media (max-width: 1024px) {
  .footer {
    padding: 20px;
  }
}
.footer-left {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media (max-width: 768px) {
  .footer-left {
    width: 100%;
  }
}
.footer-left-image {
  -o-object-fit: cover;
     object-fit: cover;
  height: 50px;
}
.footer-left-text {
  font-size: 14px;
  text-align: center;
}
.footer-middle {
  width: 33%;
  display: flex;
  align-items: end;
  justify-content: center;
}
@media (max-width: 768px) {
  .footer-middle {
    width: 100%;
  }
}
.footer-middle-text {
  text-align: center;
}
.footer-right {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
@media (max-width: 768px) {
  .footer-right {
    width: 100%;
    gap: 10px;
  }
}
.footer-right-title {
  text-align: center;
  font-size: 16px;
}
.footer-right-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.footer-right-row-link {
  color: white;
}
.footer-right-row-link-logo {
  width: 25px;
  height: 25px;
  transition: all 300ms ease-in-out;
}
.footer-right-row-link-logo:hover {
  transform: scale(1.1);
}

.modalContainer {
  background-color: #3a9d23;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalContainer-modal {
  width: 40%;
  height: 50%;
  border-radius: 15px;
  padding: 25px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 6px black;
}
@media (max-width: 1024px) {
  .modalContainer-modal {
    width: 70%;
  }
}
@media (max-width: 600px) {
  .modalContainer-modal {
    width: 77%;
    padding: 20px;
  }
}
.modalContainer-modal-top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.modalContainer-modal-top-title {
  font-size: 22px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  text-align: center;
  text-wrap: nowrap;
}
@media (max-width: 600px) {
  .modalContainer-modal-top-title {
    font-size: 18px;
    left: -10px;
    transform: translateX(0);
  }
}
.modalContainer-modal-top-close {
  font-size: 22px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .modalContainer-modal-top-close {
    font-size: 18px;
  }
}
.modalContainer-modal-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.modalContainer-modal-middle-text {
  font-size: 18px;
  text-align: center;
}
@media (max-width: 600px) {
  .modalContainer-modal-middle-text {
    font-size: 16px;
  }
}
.modalContainer-modal-middle-bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.modalContainer-modal-middle-bottom-label {
  font-weight: 500;
}
@media (max-width: 600px) {
  .modalContainer-modal-middle-bottom-label {
    font-size: 14px;
  }
}
.modalContainer-modal-middle-bottom-select {
  font-size: 16px;
  height: 35px;
  width: 100%;
}
@media (max-width: 600px) {
  .modalContainer-modal-middle-bottom-select {
    height: 25px;
    font-size: 14px;
  }
}
.modalContainer-modal-middle-bottom-select:focus {
  outline: none;
}
.modalContainer-modal-bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.modalContainer-modal-bottom-danger {
  color: red;
}
@media (max-width: 600px) {
  .modalContainer-modal-bottom-danger {
    font-size: 14px;
  }
}
.modalContainer-modal-bottom-button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  box-shadow: 0 0 8px #3A9D23;
  border-radius: 10px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  width: 100%;
}
@media (max-width: 600px) {
  .modalContainer-modal-bottom-button {
    font-size: 14px;
    padding: 5px 15px;
  }
}
.modalContainer-modal-bottom-button:hover {
  background-color: #3A9D23;
  color: white;
}
.modalContainer-modalMatchInfo {
  width: 68%;
  height: 50%;
  border-radius: 15px;
  padding: 25px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 6px black;
}
@media (max-width: 1024px) {
  .modalContainer-modalMatchInfo {
    width: 80%;
    height: 80%;
  }
}
@media (max-width: 600px) {
  .modalContainer-modalMatchInfo {
    height: 90%;
  }
}
.modalContainer-modalMatchInfo-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.modalContainer-modalMatchInfo-top-title {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  font-size: 22px;
}
@media (max-width: 600px) {
  .modalContainer-modalMatchInfo-top-title {
    font-size: 16px;
    text-wrap: nowrap;
  }
}
.modalContainer-modalMatchInfo-top-close {
  cursor: pointer;
  font-size: 22px;
}
@media (max-width: 600px) {
  .modalContainer-modalMatchInfo-top-close {
    font-size: 18px;
  }
}
.modalContainer-modalMatchInfo-middle-content {
  text-align: center;
}
@media (max-width: 600px) {
  .modalContainer-modalMatchInfo-middle-content {
    font-size: 14px;
  }
}
.modalContainer-modalGameIsFinish {
  width: 60%;
  height: 50%;
  border-radius: 15px;
  padding: 25px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 6px black;
}
@media (max-width: 1024px) {
  .modalContainer-modalGameIsFinish {
    width: 75%;
    height: 65%;
  }
}
@media (max-width: 768px) {
  .modalContainer-modalGameIsFinish {
    width: 85%;
    height: 80%;
  }
}
@media (max-width: 600px) {
  .modalContainer-modalGameIsFinish {
    width: 75%;
    height: 50%;
  }
}
.modalContainer-modalGameIsFinish-top {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalContainer-modalGameIsFinish-top-title {
  font-size: 22px;
}
@media (max-width: 600px) {
  .modalContainer-modalGameIsFinish-top-title {
    font-size: 18px;
  }
}
.modalContainer-modalGameIsFinish-middle-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.modalContainer-modalGameIsFinish-middle-row-title {
  font-size: 24px;
  text-decoration: underline;
}
@media (max-width: 600px) {
  .modalContainer-modalGameIsFinish-middle-row-title {
    font-size: 20px;
  }
}
.modalContainer-modalGameIsFinish-middle-row-value {
  font-size: 30px;
  font-weight: 800;
}
@media (max-width: 600px) {
  .modalContainer-modalGameIsFinish-middle-row-value {
    font-size: 24px;
  }
}
.modalContainer-modalGameIsFinish-bottom {
  display: flex;
  justify-content: center;
  gap: 20px;
}
@media (max-width: 600px) {
  .modalContainer-modalGameIsFinish-bottom {
    flex-direction: column;
  }
}
.modalContainer-modalGameIsFinish-bottom-button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  box-shadow: 0 0 8px #3A9D23;
  border-radius: 10px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
@media (max-width: 600px) {
  .modalContainer-modalGameIsFinish-bottom-button {
    font-size: 14px;
  }
}
.modalContainer-modalGameIsFinish-bottom-button:hover {
  background-color: #3A9D23;
  color: white;
}

.bannerMatch {
  padding: 0 100px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}
@media (max-width: 1024px) {
  .bannerMatch {
    padding: 0 50px;
  }
}
@media (max-width: 768px) {
  .bannerMatch {
    padding: 0 25px;
  }
}
@media (max-width: 600px) {
  .bannerMatch {
    padding: 0 20px;
  }
}
.bannerMatch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.bannerMatch-container-title {
  font-size: 28px;
  text-align: center;
}
@media (max-width: 768px) {
  .bannerMatch-container-title {
    font-size: 24px;
  }
}
@media (max-width: 600px) {
  .bannerMatch-container-title {
    font-size: 22px;
  }
}
.bannerMatch-container-category {
  font-size: 22px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .bannerMatch-container-category {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .bannerMatch-container-category {
    font-size: 18px;
  }
}
.bannerMatch-container-score {
  font-size: 18px;
}
@media (max-width: 768px) {
  .bannerMatch-container-score {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .bannerMatch-container-score {
    font-size: 14px;
  }
}

.gameContainer {
  display: flex;
  padding: 0 100px;
  margin-top: 50px;
  justify-content: space-between;
  height: 678px;
}
@media (max-width: 1024px) {
  .gameContainer {
    padding: 0 50px;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    height: 100%;
  }
}
@media (max-width: 600px) {
  .gameContainer {
    padding: 0 20px;
    margin-top: 25px;
  }
}

.game {
  display: flex;
  justify-content: center;
  position: relative;
  width: 576px;
}
@media (max-width: 600px) {
  .game {
    width: 280px;
  }
}
.game-image {
  width: 80%;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 2px 10px black;
  filter: opacity(0.5);
}
@media (max-width: 600px) {
  .game-image {
    height: 100%;
    width: 100%;
  }
}
.game-container {
  position: absolute;
  top: 0;
  width: 80%;
  height: 100%;
}

.score {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 20px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 8px black;
  width: 35%;
}
@media (max-width: 1024px) {
  .score {
    width: 420px;
    gap: 50px;
  }
}
@media (max-width: 600px) {
  .score {
    width: 85%;
  }
}
.score-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.score-top-title {
  font-size: 26px;
  text-decoration: underline;
}
@media (max-width: 600px) {
  .score-top-title {
    font-size: 20px;
  }
}
.score-top-error {
  font-size: 18px;
  color: red;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 6px red;
  background-color: rgba(255, 0, 0, 0.1);
}
@media (max-width: 600px) {
  .score-top-error {
    font-size: 16px;
  }
}
.score-top-firstLetter {
  font-size: 18px;
  text-align: center;
  background-color: #3A9D23;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 6px white;
}
@media (max-width: 600px) {
  .score-top-firstLetter {
    font-size: 16px;
  }
}
.score-top-firstLetter-span {
  color: black;
  padding: 5px;
  font-weight: 900;
}
.score-value {
  display: flex;
  justify-content: center;
}
.score-value-description {
  font-size: 60px;
}
@media (max-width: 600px) {
  .score-value-description {
    font-size: 40px;
  }
}
.score-joker {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.score-joker-button {
  padding: 10px 20px;
  border: none;
  background-color: white;
  color: black;
  font-size: 18px;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
}
@media (max-width: 600px) {
  .score-joker-button {
    font-size: 14px;
    padding: 10px 15px;
  }
}
.score-joker-button:hover {
  background-color: #3a9d23;
  color: white;
  transform: scale(1.05);
}
.score-joker-buttonDisabled {
  display: none;
}
.score-restart {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.score-restart-button {
  padding: 10px 20px;
  border: none;
  background-color: white;
  color: black;
  font-size: 18px;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
}
@media (max-width: 600px) {
  .score-restart-button {
    font-size: 14px;
    padding: 10px 15px;
  }
}
.score-restart-button:hover {
  background-color: #3a9d23;
  color: white;
  transform: scale(1.05);
}

.goalKepper-dom {
  z-index: 11;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}

.goalKepper-ext {
  z-index: 11;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
}

.player {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
}
@media (max-width: 600px) {
  .player {
    width: 15px;
  }
}
.player-image {
  width: 40px;
}
@media (max-width: 600px) {
  .player-image {
    width: 20px;
  }
}
.player-input {
  width: 70px;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: rgba(58, 157, 35, 0.5);
  padding: 2px;
}
@media (max-width: 600px) {
  .player-input {
    width: 30px;
    height: 10px;
  }
}
.player-input:focus {
  outline: none;
  background-color: #3A9D23;
  color: white;
}
.player-playerName {
  text-transform: capitalize;
  text-wrap: nowrap;
  font-size: 15px;
  color: black;
  font-weight: 900;
}
@media (max-width: 600px) {
  .player-playerName {
    font-size: 12px;
  }
}

.defenders-dom {
  display: flex;
  position: absolute;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
  gap: 60px;
}
@media (max-width: 600px) {
  .defenders-dom {
    top: 50px;
    gap: 50px;
  }
}
.defenders-ext {
  display: flex;
  position: absolute;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
  gap: 60px;
}
@media (max-width: 600px) {
  .defenders-ext {
    bottom: 50px;
    gap: 50px;
  }
}

.midfielders-dom {
  display: flex;
  position: absolute;
  top: 170px;
  left: 50%;
  transform: translateX(-50%);
  gap: 50px;
}
.midfielders-dom-4231 {
  display: flex;
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  gap: 50px;
  position: relative;
}
@media (max-width: 600px) {
  .midfielders-dom-4231 {
    top: 140px;
  }
}
.midfielders-dom-4231-element:nth-child(1) {
  position: absolute;
  left: 30px;
}
@media (max-width: 600px) {
  .midfielders-dom-4231-element:nth-child(1) {
    left: 5px;
  }
}
.midfielders-dom-4231-element:nth-child(2) {
  position: absolute;
  right: 30px;
}
@media (max-width: 600px) {
  .midfielders-dom-4231-element:nth-child(2) {
    right: 5px;
  }
}
.midfielders-dom-4231-element:nth-child(3) {
  position: absolute;
  top: -45px;
  right: 30%;
}
.midfielders-dom-4231-element:nth-child(4) {
  position: absolute;
  top: -45px;
  left: 30%;
}
.midfielders-dom-4231-element:nth-child(5) {
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translate(-50%);
}
@media (max-width: 600px) {
  .midfielders-dom-4231-element:nth-child(5) {
    top: -25px;
  }
}
.midfielders-dom-433 {
  display: flex;
  position: absolute;
  transform: translateX(-50%);
  gap: 50px;
  top: 170px;
  left: 50%;
}
.midfielders-dom-433-element:first-child {
  position: absolute;
  left: -120px;
  top: 20px;
}
.midfielders-dom-433-element:nth-child(2) {
  position: absolute;
  left: -28px;
  top: 0px;
}
.midfielders-dom-433-element:nth-child(3) {
  position: absolute;
  right: -120px;
  top: 20px;
}
.midfielders-ext {
  display: flex;
  position: absolute;
  bottom: 170px;
  left: 50%;
  transform: translateX(-50%);
  gap: 50px;
}
.midfielders-ext-4231 {
  display: flex;
  position: absolute;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  gap: 50px;
  position: relative;
}
.midfielders-ext-4231-element:nth-child(1) {
  position: absolute;
  left: 30px;
}
.midfielders-ext-4231-element:nth-child(2) {
  position: absolute;
  right: 30px;
}
.midfielders-ext-4231-element:nth-child(3) {
  position: absolute;
  bottom: -45px;
  right: 30%;
}
.midfielders-ext-4231-element:nth-child(4) {
  position: absolute;
  bottom: -45px;
  left: 30%;
}
.midfielders-ext-4231-element:nth-child(5) {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translate(-50%);
}
.midfielders-ext-433 {
  display: flex;
  position: absolute;
  transform: translateX(-50%);
  gap: 50px;
  bottom: 170px;
  left: 50%;
}
@media (max-width: 600px) {
  .midfielders-ext-433 {
    bottom: 100px;
  }
}
.midfielders-ext-433-element:first-child {
  position: absolute;
  left: -120px;
  bottom: 20px;
}
@media (max-width: 600px) {
  .midfielders-ext-433-element:first-child {
    left: -80px;
  }
}
.midfielders-ext-433-element:nth-child(2) {
  position: absolute;
  transform: translateX(-50%);
  bottom: 0px;
}
.midfielders-ext-433-element:nth-child(3) {
  position: absolute;
  right: -120px;
  bottom: 20px;
}
@media (max-width: 600px) {
  .midfielders-ext-433-element:nth-child(3) {
    right: -80px;
  }
}

.forwards-dom {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 260px;
  gap: 100px;
}
@media (max-width: 600px) {
  .forwards-dom {
    top: 160px;
  }
}
.forwards-ext {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 260px;
  gap: 100px;
}
@media (max-width: 600px) {
  .forwards-ext {
    bottom: 150px;
  }
}/*# sourceMappingURL=styles.css.map */