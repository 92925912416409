.modalContainer {
    background-color: rgba($color: #3A9D23, $alpha: 1);
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &-modal {
        width: 40%;
        height: 50%;
        border-radius: 15px;
        padding: 25px;
        background-color: white;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 0 6px black;

        @include XL {
            width: 70%;
        }

        @include M {
            width: 77%;
            padding: 20px;
        }

        &-top {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            &-title {
                font-size: 22px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -2px;
                text-align: center;
                text-wrap: nowrap;

                @include M {
                    font-size: 18px;
                    left: -10px;
                    transform: translateX(0);
                }
            }

            &-close {
                font-size: 22px;
                cursor: pointer;

                @include M {
                    font-size: 18px;
                }
            }
        }

        &-middle {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            &-text {
                font-size: 18px;
                text-align: center;

                @include M {
                    font-size: 16px;
                }
            }

            &-bottom {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;

                &-label {
                    font-weight: 500;
                    
                    @include M {
                        font-size: 14px;
                    }
                }

                &-select {
                    font-size: 16px;
                    height: 35px;
                    width: 100%;

                    @include M {
                        height: 25px;
                        font-size: 14px;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        &-bottom {
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;

            &-danger {
                color: red;

                @include M {
                    font-size: 14px;
                }
            }

            &-button {
                font-size: 16px;
                padding: 10px 20px;
                background-color: transparent;
                border: none;
                box-shadow: 0 0 8px #3A9D23;
                border-radius: 10px;
                cursor: pointer;
                transition: all 300ms ease-in-out;
                width: 100%;

                @include M {
                    font-size: 14px;
                    padding: 5px 15px;
                }

                &:hover {
                    background-color: #3A9D23;
                    color: white;
                }
            }
        }
    }

    &-modalMatchInfo {
        width: 68%;
        height: 50%;
        border-radius: 15px;
        padding: 25px;
        background-color: white;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 0 6px black;

        @include XL {
            width: 80%;
            height: 80%;
        }

        @include M {
            height: 90%;
        }

        &-top {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;

            &-title {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                font-size: 22px;

                @include M {
                    font-size: 16px;
                    text-wrap: nowrap;
                }
            }

            &-close {
                cursor: pointer;
                font-size: 22px;

                @include M {
                    font-size: 18px;
                }
            }
        }

        &-middle {
            
            &-content {
                text-align: center;

                @include M {
                    font-size: 14px;
                }
            }
        }
    }

    &-modalGameIsFinish {
        width: 60%;
        height: 50%;
        border-radius: 15px;
        padding: 25px;
        background-color: white;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 0 6px black;

        @include XL {
            width: 75%;
            height: 65%;
        }

        @include L {
            width: 85%;
            height: 80%;
        }

        @include M {
            width: 75%;
            height: 50%;
        }

        &-top {
            display: flex;
            justify-content: center;
            align-items: center;

            &-title {
                font-size: 22px;

                @include M {
                    font-size: 18px;
                }
            }
        }

        &-middle {

            &-row {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                &-title {
                    font-size: 24px;
                    text-decoration: underline;

                    @include M {
                        font-size: 20px;
                    }
                }

                &-value {
                    font-size: 30px;
                    font-weight: 800;

                    @include M {
                        font-size: 24px;
                    }
                }
            }
        }
        &-bottom {
            display: flex;
            justify-content: center;
            gap: 20px;

            @include M {
                flex-direction: column;
            }

            &-button {
                font-size: 16px;
                padding: 10px 20px;
                background-color: transparent;
                border: none;
                box-shadow: 0 0 8px #3A9D23;
                border-radius: 10px;
                cursor: pointer;
                transition: all 300ms ease-in-out;

                @include M {
                    font-size: 14px;
                }

                &:hover {
                    background-color: #3A9D23;
                    color: white;
                }
            }
        }
    }
}