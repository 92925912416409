.cards {
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 50px;

    @include XL {
        padding: 0 50px;
    }

    @include L {
        gap: 25px;
        padding: 0 25px;
    }

    @include M {
        padding: 0 20px;
    }

    &-row {
        display: flex;
        justify-content: space-between;

        @include L {
            flex-direction: column;
            align-items: center;
            gap: 25px;
        }

        &-container {
            background-color: #3A9D23;
            display: flex;
            flex-direction: column;
            box-shadow: 0 0 6px white;
            border-radius: 10px;
            align-items: center;
            width: 43%;
            padding: 20px;
            gap: 25px;
            transition: all 300ms ease-in-out;

            @include L {
                width: 90%;
            }

            &:hover {
                box-shadow: 0 0 50px white;
                transform: scale(1.05);
            }

            &-image {
                width: 75%;
                border-radius: 10px;
                box-shadow: 0 0 6px white;

                @include XL {
                    width: 95%;
                }

                @include L {
                    width: 70%;
                    height: 300px;
                }

                @include M {
                    height: 250px;
                    width: 80%;
                }

                @include S {
                    height: 200px;
                    width: 100%;
                }
            }

            &-title {
                font-size: 23px;
                font-weight: 900;
                text-decoration: underline;

                @include M {
                    font-size: 18px;
                }
            }

            &-description {
                text-align: center;
                letter-spacing: 1px;
                
                @include M {
                    font-size: 14px;
                }
            }
        }
    }
}