.footer {
    margin-top: 50px;
    background-color: black;
    display: flex;
    padding: 30px;
    border-radius: 10px 10px 0 0;

    @include L {
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }

    @include XL {
        padding: 20px;
    }

    &-left {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @include L {
            width: 100%;
        }

        &-image {
            object-fit: cover;
            height: 50px;
        }

        &-text {
            font-size: 14px;
            text-align: center;
        }
    }

    &-middle {
        width: 33%;
        display: flex;
        align-items: end;
        justify-content: center;

        @include L {
            width: 100%;
        }

        &-text {
            text-align: center;
        }
    }

    &-right {
        width: 33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        @include L {
            width: 100%;
            gap: 10px;
        }

        &-title {
            text-align: center;
            font-size: 16px;
        }

        &-row {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            &-link {
                color: white;

                &-logo {
                    width: 25px;
                    height: 25px;
                    transition: all 300ms ease-in-out;

                    &:hover {
                        transform: scale(1.10);
                    }
                }
            }
        }
    }
}