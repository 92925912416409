.game {
    display: flex;
    justify-content: center;
    position: relative;
    width: 576px;
    
    @include M {
        width: 280px;
    }
    
    &-image {
        width: 80%;
        position: relative;
        border-radius: 10px;
        box-shadow: 0 2px 10px black;
        filter: opacity(0.5);
        
        @include M {
            height: 100%;
            width: 100%;
        }
    }

    &-container {
        position: absolute;
        top: 0;
        width: 80%;
        height: 100%;
    }
}