.bannerHome {
    margin-top: 50px;
    padding: 0 100px;

    @include XL {
        padding: 0 50px;
    }

    @include L {
        padding: 0 25px;
    }

    @include M {
        padding: 0 20px;
    }

    &-container {
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: center;

        &-image {
            object-fit: cover;
            height: 50%;
            width: 40%;
            border-radius: 10px;
            box-shadow: 0 0 6px white;

            @include XL {
                height: 70%;
                width: 60%;
            }

            @include L {
                height: 80%;
                width: 70%;
            }

            @include M {
                width: 100%;
            }
        }

        &-title {
            text-align: center;
            width: 70%;

            @include L {
                font-size: 22px;
            }

            @include M {
                width: 100%;
                font-size: 20px;
            }
        }

        &-link {
            text-decoration: none;
            color: white;
            box-shadow: 0 0 6px black;
            border-radius: 10px;
            background-color: black;
            padding: 10px 20px;
            letter-spacing: 1px;
            transition: all 300ms ease-in-out;

            @include M {
                font-size: 14px;
            }

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}