.gameContainer {
    display: flex;
    padding: 0 100px;
    margin-top: 50px;
    justify-content: space-between;
    height: 678px;

    @include XL {
        padding: 0 50px;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        height: 100%;
    }

    @include M {
        padding: 0 20px;
        margin-top: 25px;
    }
}