.player {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;

    @include M {
        width: 15px;
    }

    &-image {
        width: 40px;

        @include M {
            width: 20px;
        }
    }

    &-input {
        width: 70px;
        border-top: none;
        border-left: none;
        border-right: none;
        background-color: rgba($color: #3A9D23, $alpha: 0.5);
        padding: 2px;

        @include M {
            width: 30px;
            height: 10px;
        }

        &:focus {
            outline: none;
            background-color: #3A9D23;
            color: white;
        }
    }

    &-playerName {
        text-transform: capitalize;
        text-wrap: nowrap;
        font-size: 15px;
        color: black;
        font-weight: 900;
        
        @include M {
            font-size: 12px;
        }
    }
}