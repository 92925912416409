.forwards {

    &-dom {
        display: flex;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 260px;
        gap: 100px;

        @include M {
            top: 160px;
        }
    }

    &-ext {
        display: flex;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 260px;
        gap: 100px;

        @include M {
            bottom: 150px;
        }
    }
}