.midfielders {

    &-dom {
        display: flex;
        position: absolute;
        top: 170px;
        left: 50%;
        transform: translateX(-50%);
        gap: 50px;

        &-4231 {
            display: flex;
            position: absolute;
            top: 200px;
            left: 50%;
            transform: translateX(-50%);
            gap: 50px;
            position: relative;

            @include M {
                top: 140px;
            }

            &-element:nth-child(1) {
                position: absolute;
                left: 30px;

                @include M {
                    left: 5px;
                }
            }

            &-element:nth-child(2) {
                position: absolute;
                right: 30px;

                @include M {
                    right: 5px;
                }
            }

            &-element:nth-child(3) {
                position: absolute;
                top: -45px;
                right: 30%;
            }

            &-element:nth-child(4) {
                position: absolute;
                top: -45px;
                left: 30%;
            }

            &-element:nth-child(5) {
                position: absolute;
                left: 50%;
                top: -10px;
                transform: translate(-50%);

                @include M {
                    top: -25px;
                }
            }
        }

        &-433 {
            display: flex;
            position: absolute;
            transform: translateX(-50%);
            gap: 50px;
            top: 170px;
            left: 50%;

            &-element:first-child {
                position: absolute;
                left: -120px;
                top: 20px;
            }

            &-element:nth-child(2){
                position: absolute;
                left: -28px;
                top: 0px;
            }

            &-element:nth-child(3){
                position: absolute;
                right: -120px;
                top: 20px;
            }
        }
    }

    &-ext {
        display: flex;
        position: absolute;
        bottom: 170px;
        left: 50%;
        transform: translateX(-50%);
        gap: 50px;

        &-4231 {
            display: flex;
            position: absolute;
            bottom: 200px;
            left: 50%;
            transform: translateX(-50%);
            gap: 50px;
            position: relative;

            &-element:nth-child(1) {
                position: absolute;
                left: 30px;
            }

            &-element:nth-child(2) {
                position: absolute;
                right: 30px;
            }

            &-element:nth-child(3) {
                position: absolute;
                bottom: -45px;
                right: 30%;
            }

            &-element:nth-child(4) {
                position: absolute;
                bottom: -45px;
                left: 30%;
            }

            &-element:nth-child(5) {
                position: absolute;
                left: 50%;
                bottom: -10px;
                transform: translate(-50%);
            }
        }

        &-433 {
            display: flex;
            position: absolute;
            transform: translateX(-50%);
            gap: 50px;
            bottom: 170px;
            left: 50%;

            @include M {
                bottom: 100px;
            }

            &-element:first-child {
                position: absolute;
                left: -120px;
                bottom: 20px;

                @include M {
                    left: -80px;
                }
            }

            &-element:nth-child(2){
                position: absolute;
                transform: translateX(-50%);
                bottom: 0px;
            }

            &-element:nth-child(3){
                position: absolute;
                right: -120px;
                bottom: 20px;

                @include M {
                    right: -80px;
                }
            }
        }
    }
}