
@mixin XL {
    @media (max-width: 1024px) {
        @content
    }
};

@mixin L {
    @media (max-width: 768px) {
        @content
    }
};

@mixin M {
    @media (max-width: 600px) {
        @content
    }
};

@mixin S {
    @media (max-width: 425px) {
        @content
    }
};