.defenders {
    
    &-dom {
        display: flex;
        position: absolute;
        top: 85px;
        left: 50%;
        transform: translateX(-50%);
        gap: 60px;

        @include M {
            top: 50px;
            gap: 50px;
        }
    }

    &-ext {
        display: flex;
        position: absolute;
        bottom: 85px;
        left: 50%;
        transform: translateX(-50%);
        gap: 60px;

        @include M {
            bottom: 50px;
            gap: 50px;
        }
    }
}